import { useEffect } from 'react';

const canUseDOM = () => {
    if(typeof window === 'undefined' || !window.document || !window.document.createElement ){
        return false
    }
    return true
}

const Freshdesk = () => {
    function initFreshChat() {
        console.log('init freshchat');
        if(window.fcWidget){
            window.fcWidget.init({
                token: "bf723dbf-5730-4f48-8805-2a803206cc07",
                host: "https://wchat.au.freshchat.com",
                open: true,
                config: {
                    showFAQOnOpen: false,
                    hideFAQ: true,
                }
            });
        }
    }
    function initialize(i,t){
        if(i.getElementById(t)){
            initFreshChat()
        } else {
            const firstScript = document.getElementsByTagName("script")[0];
            const script = document.createElement('script');
            script.type = "text/javascript";
            script.async = true;
            script.id = t;
            script.src = `https://wchat.au.freshchat.com/js/widget.js`;
            script.onload = initFreshChat
            firstScript.parentNode.insertBefore(script,firstScript);
        }
    }
    function initiateCall(){
        initialize(document,"Freshdesk-Messaging-js-sdk")
    }

    useEffect(() => {
        if(canUseDOM && !window.fcWidget){
            initiateCall();
        }
        return () => {
            if (!canUseDOM || !window.fcWidget) {
                return
            }
            delete window.fcWidget
            window.removeEventListener('load',() => {});
        }
    },[]);

    return null
}

export default Freshdesk;