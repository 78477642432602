import styles from './App.module.scss';
import Freshdesk from './Freshdesk';

function App() {
  return (
    <>
      <div className={`${styles.App} ${styles.backgroundImageContainer}`} />
      <Freshdesk />
    </>
  );
}

export default App;
